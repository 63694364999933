import { useTranslation } from 'next-i18next'
import { Skeleton } from '../../components/skeleton'
import { Text } from '../../components/text'

type StudySuggestionsProp = Partial<{
  descriptionHtml: string
  showSkeleton: boolean
}>

const StudySuggestions = ({ descriptionHtml, showSkeleton }: StudySuggestionsProp) => {
  const { t } = useTranslation()

  return (
    <div>
      <Text component='h5' variant='heading_m_500' className='mb-3'>
        {t('crowdin:product-detail-page.tabs.moneyback.study-suggestions.title')}
      </Text>
      {descriptionHtml ? (
        <Text
          dangerouslySetInnerHTML={{ __html: descriptionHtml }}
          variant='body_m_400'
          className='whitespace-pre-wrap break-words'
        />
      ) : (
        showSkeleton && <Skeleton className='h-24 w-full' />
      )}
    </div>
  )
}

export { StudySuggestions, type StudySuggestionsProp }
