import { ENV } from './constants'

export type APP_ENV_TYPE = 'production' | 'staging' | 'dev'

export const STAGE = process.env.NEXT_PUBLIC_STAGE

export const isProd = STAGE === ENV.PROD

export const isStaging = STAGE === ENV.STAGING

export const isDev = STAGE === ENV.DEV
