import { ENV, type Stage } from './constants'

export const STAGE = process.env.NEXT_PUBLIC_STAGE as Stage

export const isProd = STAGE === ENV.PROD

export const isStaging = STAGE === ENV.STAGING

export const isDev = STAGE === ENV.DEV

export const isInClient = () => {
  return typeof window !== 'undefined'
}
