import { Icon } from '@iconify/react'
import Swal, { type SweetAlertIcon, type SweetAlertResult } from 'sweetalert2'
import withReactContent, { type ReactSweetAlertOptions } from 'sweetalert2-react-content'
import { paletteColors } from '@wordup/assets/colors'

const TailwindColor = paletteColors

type TailwindColorTypes = keyof typeof paletteColors

const sweetalert = withReactContent(Swal)

export type CustomizedReactSweetAlertOptions = ReactSweetAlertOptions & {
  cancelButtonColor?: TailwindColorTypes
  confirmButtonColor?: TailwindColorTypes
}

const customizedSwal = {
  ...sweetalert,
  fire: async (options: CustomizedReactSweetAlertOptions): Promise<SweetAlertResult> => {
    return sweetalert.fire({
      ...options,
      cancelButtonColor: options.cancelButtonColor
        ? TailwindColor[options.cancelButtonColor]
        : TailwindColor['grayscale-300'],
      confirmButtonColor: options.confirmButtonColor
        ? TailwindColor[options.confirmButtonColor]
        : options.icon === 'error' || options.icon === 'warning'
        ? TailwindColor['peach-500']
        : TailwindColor['primary-500'],
      iconHtml: options.icon ? getCustomizedIcon(options.icon) : undefined,
      customClass: options.customClass || {
        icon: 'popup_confirmation_icon',
        popup: 'popup_confirmation_popup',
        container: 'popup_confirmation_container',
        htmlContainer: 'popup_confirmation_html_container',
        title: 'popup_confirmation_title',
        actions: 'popup_confirmation_actions',
        confirmButton: 'popup_confirmation_confirm_button',
        cancelButton: 'popup_confirmation_cancel_button',
        denyButton: 'popup_confirmation_deny_button',
      },
    })
  },
}

export default customizedSwal

const getCustomizedIcon = (icon: SweetAlertIcon) => {
  switch (icon) {
    case 'success': {
      return (
        <Icon
          icon='icon-park-outline:check-one'
          className='h-[3.75rem] w-[3.75rem] text-green-500'
        />
      )
    }
    case 'warning':
      return (
        <Icon
          icon='mdi:alert-rhombus-outline'
          className='h-[3.75rem] w-[3.75rem] text-yellow-500'
        />
      )
    case 'error':
      return (
        <Icon icon='mdi:close-octagon-outline' className='text-peach-500 h-[3.75rem] w-[3.75rem]' />
      )
    case 'info':
      return (
        <Icon
          icon='mdi:information-outline'
          className='text-grayscale-300 h-[3.75rem] w-[3.75rem]'
        />
      )
    default:
      return undefined
  }
}
