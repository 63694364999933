export const getVideoThumbnailFromFile = ({
  file,
  thumbnailWidth,
  thumbnailHeight,
  thumbnailTimestampSeconds,
}: {
  file: File
  thumbnailWidth?: number
  thumbnailHeight?: number
  thumbnailTimestampSeconds?: number
}): Promise<File> => {
  return new Promise((resolve, reject) => {
    try {
      const canvas = document.createElement('canvas')
      const video = document.createElement('video')

      video.preload = 'auto'
      video.src = URL.createObjectURL(file)

      video.oncanplay = () => {
        canvas.width = thumbnailWidth ?? video.videoWidth
        canvas.height = thumbnailHeight ?? video.videoHeight

        const ctx = canvas.getContext('2d')
        if (!ctx) {
          reject(new Error('Could not get 2d context'))
          return
        }

        video.currentTime = thumbnailTimestampSeconds ?? 2
        video.pause()

        video.onseeked = () => {
          ctx.drawImage(
            video,
            0,
            0,
            thumbnailWidth ?? video.videoWidth,
            thumbnailHeight ?? video.videoHeight,
          )

          canvas.toBlob(blob => {
            if (!blob) {
              reject(new Error('Could not create blob'))
              return
            }

            const thumbnailFile = new File([blob], 'thumbnail.png', { type: 'image/png' })

            video.remove()
            video.onseeked = null
            video.oncanplay = null
            URL.revokeObjectURL(video.src)
            resolve(thumbnailFile)
          }, 'image/png')
        }
      }

      video.onerror = () => {
        reject(new Error('Could not load video'))
      }

      video.load()
    } catch (err) {
      reject(err)
    }
  })
}
