export const paletteColors = {
  'green-100': '#daf4de',
  'green-300': '#acebc2',
  'green-500': '#16c172',
  'green-700': '#0a8a69',
  'green-900': '#075a54',
  'peach-100': '#feebda',
  'peach-300': '#fbb291',
  'peach-500': '#f06449',
  'peach-700': '#ac2424',
  'peach-900': '#730e1c',
  'yellow-100': '#fffbcc',
  'yellow-300': '#ffed66',
  'yellow-500': '#ffde00',
  'yellow-700': '#bea917',
  'yellow-900': '#75680d',
  'purple-100': '#f8e9ff',
  'purple-300': '#e5bcff',
  'purple-500': '#c890ff',
  'purple-700': '#7548b8',
  'purple-900': '#381b7a',
  'aqua-100': '#ccf0ed',
  'aqua-300': '#8adce2',
  'aqua-500': '#42badb',
  'aqua-700': '#21709d',
  'aqua-900': '#0c3a6a',
  'coral-100': '#feece2',
  'coral-300': '#fcb9a8',
  'coral-500': '#f8736e',
  'coral-700': '#b23847',
  'coral-900': '#771532',
  'primary-100': '#e2f2ff',
  'primary-200': '#c6e6ff',
  'primary-300': '#abd6ff',
  'primary-400': '#94c7fe',
  'primary-500': '#72afff',
  'primary-600': '#5488db',
  'primary-700': '#3a64b8',
  'primary-800': '#244594',
  'primary-900': '#152f79',
  'grayscale-50': '#F4F5F5',
  'grayscale-000': '#fff',
  'grayscale-100': '#eeefef',
  'grayscale-200': '#dedfdf',
  'grayscale-300': '#bdc0c0',
  'grayscale-400': '#9da1a1',
  'grayscale-500': '#7c8282',
  'grayscale-600': '#5c6363',
  'grayscale-700': '#464d4d',
  'grayscale-800': '#383b3d',
  'grayscale-900': '#2a2e2e',
  'grayscale-1000': '#151717',
  'grayscale-1100': '#0e1010',

  'background-light': '#f6f8fc',
  'background-dark': '#151717',

  white: '#ffffff',
  black: '#000000',

  'focused-primary': '#E2F2FF80',
}

type BaseType = typeof paletteColors

export type PaletteColorKeysType = keyof BaseType

export const darkModePaletteColors: BaseType = {
  'green-100': '#075a54',
  'green-300': '#0a8a69',
  'green-500': '#16c172',
  'green-700': '#acebc2',
  'green-900': '#daf4de',
  'peach-100': '#730e1c',
  'peach-300': '#ac2424',
  'peach-500': '#f06449',
  'peach-700': '#fbb291',
  'peach-900': '#feebda',
  'yellow-100': '#fffbcc',
  'yellow-300': '#ffed66',
  'yellow-500': '#ffde00',
  'yellow-700': '#bea917',
  'yellow-900': '#75680d',
  'purple-100': '#381b7a',
  'purple-300': '#7548b8',
  'purple-500': '#c890ff',
  'purple-700': '#e5bcff',
  'purple-900': '#f8e9ff',
  'aqua-100': '#0c3a6a',
  'aqua-300': '#21709d',
  'aqua-500': '#42badb',
  'aqua-700': '#8adce2',
  'aqua-900': '#ccf0ed',
  'coral-100': '#771532',
  'coral-300': '#b23847',
  'coral-500': '#f8736e',
  'coral-700': '#fcb9a8',
  'coral-900': '#feece2',
  'primary-100': '#152f79',
  'primary-200': '#244594',
  'primary-300': '#3a64b8',
  'primary-400': '#5488db',
  'primary-500': '#72afff',
  'primary-600': '#94c7fe',
  'primary-700': '#abd6ff',
  'primary-800': '#c6e6ff',
  'primary-900': '#e2f2ff',
  'grayscale-000': '#151717',
  'grayscale-50': '#2a2e2e',
  'grayscale-100': '#383b3d',
  'grayscale-200': '#464d4d',
  'grayscale-300': '#5c6363',
  'grayscale-400': '#7c8282',
  'grayscale-500': '#9da1a1',
  'grayscale-600': '#bdc0c0',
  'grayscale-700': '#dedfdf',
  'grayscale-800': '#eeefef',
  'grayscale-900': '#F4F5F5',
  'grayscale-1000': '#fff', // switch with grayscale 000
  'grayscale-1100': '#0e1010',

  'background-light': '#151717', // switch with background dark
  'background-dark': '#f6f8fc', // switch with background light

  white: '#ffffff',
  black: '#000000',

  'focused-primary': '#E2F2FF80',
}
