import type { OrgTrackingTypes } from './types'
import snakecaseKeys from 'snakecase-keys'

export const getOrgsId = ({ orgId }: { orgId: string }) => ({
  url: `/v1/orgs/${orgId}`,
  method: 'GET',
})

export const updateOrgsTracking = ({
  orgId,
  params,
}: {
  orgId: string
  params: OrgTrackingTypes
}) => ({
  url: `/v1/orgs/${orgId}`,
  method: 'PATCH',
  data: {
    id: orgId,
    ...snakecaseKeys(params),
  },
})
