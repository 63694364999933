import type { CredentialsType } from '@wordup/apis'
import { isEmpty } from 'lodash-es'
import { createCookieStorage } from '../storage'
import { createLocalStorage } from '../storage'
import { isInClient, STAGE, type Stage } from '../env'
import { LONGEST_COOKIE_EXPIRARY_TIME, USER_RELATED_KEY } from '../constants'

const CookieUtils = createCookieStorage(STAGE as Stage)
const LocalStorageUtils = createLocalStorage(STAGE as Stage)

interface HeadersProps {
  lang?: string
  clientInfo?: string
  endUserClientInfo?: string
  credentials?: CredentialsType
}

const createAuthInstance = (domain?: string) => {
  domain = domain || process.env.NEXT_PUBLIC_ROOT_DOMAIN

  const saveCredential = (credential: CredentialsType) => {
    CookieUtils.set({
      key: USER_RELATED_KEY.CREDENTIAL,
      value: JSON.stringify(credential),
      expires: LONGEST_COOKIE_EXPIRARY_TIME,
      domain,
    })
  }

  const getCredential = (): CredentialsType | {} => {
    const credFromLocalstorage =
      LocalStorageUtils.get(USER_RELATED_KEY.CREDENTIAL, false, false) || ({} as any)
    let credFromCookie = CookieUtils.get(USER_RELATED_KEY.CREDENTIAL, true)

    if (!isEmpty(credFromLocalstorage)) {
      const platformInfo = LocalStorageUtils.get(USER_RELATED_KEY.PLATFORM, true, false) as any
      const isFromApp = platformInfo && platformInfo.platform

      if (isFromApp) {
        saveCredential(credFromLocalstorage)
        credFromCookie = credFromLocalstorage
      } else {
        if (isEmpty(credFromCookie)) {
          saveCredential(credFromLocalstorage)
          credFromCookie = credFromLocalstorage
        }
        LocalStorageUtils.remove(USER_RELATED_KEY.CREDENTIAL)
      }
    }

    return credFromCookie || {}
  }

  const checkHasCredential = () => {
    const cred = getCredential()
    return 'uid' in cred && Boolean(cred.uid)
  }

  const getUserEmail = () => {
    if (typeof window === 'undefined') return null
    return (getCredential() as any)?.uid
  }

  const saveUserId = (userId: string) => {
    if (typeof window === 'undefined') return null
    return CookieUtils.set({
      key: USER_RELATED_KEY.USER_ID,
      value: String(userId || ''),
      expires: LONGEST_COOKIE_EXPIRARY_TIME,
      domain,
    })
  }

  const clearCookies = () => {
    ;[USER_RELATED_KEY.CREDENTIAL, USER_RELATED_KEY.USER_ID, USER_RELATED_KEY.USER_INFO].forEach(
      key => {
        CookieUtils.del(key, { domain })
      },
    )
  }

  const requestHeaders = ({ lang, clientInfo, endUserClientInfo, credentials }: HeadersProps) => {
    return new Headers({
      'Content-Type': 'application/json',
      'Client-Lang': lang || 'zh-TW',
      'Client-Info': clientInfo || 'N/A',
      'End-User-Client-Info': endUserClientInfo || 'N/A',
      ...credentials,
    })
  }

  const getUserId = () => {
    if (isInClient()) return CookieUtils.get<string>(USER_RELATED_KEY.USER_ID)
    return null
  }

  const saveUserName = (userName = '') => {
    if (isInClient()) {
      return CookieUtils.set({
        key: USER_RELATED_KEY.USER_NAME,
        value: userName,
        expires: LONGEST_COOKIE_EXPIRARY_TIME,
        domain,
      })
    }
    return null
  }

  const getUserName = () => {
    if (isInClient()) {
      return CookieUtils.get<string>(USER_RELATED_KEY.USER_NAME)
    }
    return null
  }

  return {
    saveCredential,
    getCredential,
    requestHeaders,
    checkHasCredential,
    getUserId,
    saveUserId,
    getUserName,
    saveUserName,
    getUserEmail,
    clearCookies,
  }
}

export { createAuthInstance }
