export const getManageProducts = ({
  q,
  page,
  orgId,
  shopReady,
  shopListed,
}: {
  q?: string
  page?: number
  orgId: string
  shopReady?: boolean
  shopListed?: boolean
}) => ({
  method: 'get',
  url: `/manage/products`,
  params: { q, page, org_id: orgId, shop_ready: shopReady, shop_listed: shopListed },
})
