import { useTranslation } from 'next-i18next'
import Link from 'next/link'

import { SHOP_PATH } from '../../utils/routes'
import { Icon } from '../icon'

export const SuccessfullyAddCartToastContent = () => {
  const { t } = useTranslation()

  return (
    <div className='flex items-center justify-between'>
      <div>{t('cart.noti.added')}</div>

      <Link href={SHOP_PATH.CART} className='no-underline'>
        <div className='text-grayscale-000 mr-3 flex items-center gap-2 rounded-lg bg-green-500 p-[4px_8px] text-sm'>
          {t('cart.noti.go')}
          <Icon type='keyboard_arrow_right' />
        </div>
      </Link>
    </div>
  )
}
