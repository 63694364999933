import { wrapWarnedOnce } from './warned-once'

const wrapDeprecatedConsole = <Args extends any[], R>(
  func: (...args: Args) => R,
  messages: string[],
) => {
  return wrapWarnedOnce(func, [
    `[DEPRECATED]: This temporary wrap will be removed in future versions.`,
    '',
    ...messages,
  ])
}

export { wrapDeprecatedConsole }
