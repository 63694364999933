import {
  isValidElement,
  type ComponentProps,
  type ReactElement,
  type ReactNode,
  type PropsWithChildren,
} from 'react'
import { Trans } from 'next-i18next'
import { Skeleton } from '../../components/skeleton'
import { Text, type TextPropsTypes } from './base'

const DEFAULT_COMPONENT = {
  Skeleton,
  HighlightBold: ({
    children,
    hihglightColor = 'peach-500',
  }: PropsWithChildren<Pick<HighlightableTextProps, 'hihglightColor'>>) => (
    <Text variant='body_m_500' color={hihglightColor}>
      {children}
    </Text>
  ),
  Highlight: ({
    children,
    hihglightColor = 'peach-500',
  }: PropsWithChildren<Pick<HighlightableTextProps, 'hihglightColor'>>) => (
    <Text variant='body_m_400' color={hihglightColor}>
      {children}
    </Text>
  ),
  HighlightWithUnderline: ({
    children,
    hihglightColor = 'peach-500',
  }: PropsWithChildren<Pick<HighlightableTextProps, 'hihglightColor'>>) => (
    <Text variant='body_m_400' color={hihglightColor} className='underline underline-offset-2'>
      {children}
    </Text>
  ),
}

type MutableTransComponentsProps = Record<string, ReactNode>

type TransComponentsProps<TagNames extends string = string> = {
  readonly [tagName in TagNames]: ReactElement
}

type DefaultTransComponentsProps = TransComponentsProps<
  'Highlight' | 'HighlightBold' | 'HighlightWithUnderline'
>

type HighlightableTextProps = TextPropsTypes &
  Partial<{
    hihglightColor: TextPropsTypes['color']
    transProps: Omit<ComponentProps<typeof Trans>, 'components'> & {
      components?:
        | TransComponentsProps
        | ((values: any, helperComponent: typeof DEFAULT_COMPONENT) => MutableTransComponentsProps)
    }
  }>

const HighlightableText = ({
  children,
  hihglightColor = 'peach-500',
  transProps,
  ...textProps
}: HighlightableTextProps) => {
  const DEFAULT_REACT_ELEMENT: DefaultTransComponentsProps = {
    Highlight: <DEFAULT_COMPONENT.Highlight hihglightColor={hihglightColor} />,
    HighlightBold: <DEFAULT_COMPONENT.HighlightBold hihglightColor={hihglightColor} />,
    HighlightWithUnderline: (
      <DEFAULT_COMPONENT.HighlightWithUnderline hihglightColor={hihglightColor} />
    ),
  } as const

  const dynamicElement =
    typeof transProps?.components === 'function'
      ? Object.entries(transProps.components(transProps?.values || {}, DEFAULT_COMPONENT)).reduce(
          (acc, [key, component]) => {
            if (isValidElement(component)) acc[key] = component
            return acc
          },
          {} as MutableTransComponentsProps,
        )
      : transProps?.components

  return (
    <Text {...textProps}>
      <Trans {...transProps} components={{ ...DEFAULT_REACT_ELEMENT, ...dynamicElement }}>
        {children}
      </Trans>
    </Text>
  )
}

export { HighlightableText, type HighlightableTextProps }
