import _some from 'lodash/some'

const replacements = [
  { from: /[´`ʹ’‘]/g, to: "'" },
  { from: /[“”ˮ‟„]/g, to: '"' },
  { from: /[…⋯]/g, to: '...' },
  { from: /[‚]/g, to: ',' },
]

const sanitize = (text: string) => {
  return replacements.reduce((acc, { from, to }) => acc.replace(from, to), text)
}

export const checkIsSpellingCorrect = ({
  word,
  correctWords = [],
}: {
  word: string
  correctWords: string[]
}) => {
  if (!word) return false
  word = sanitize(word.trim())

  const sanitizedCorrectWords = correctWords.map(w => sanitize(w.trim()))

  return _some(sanitizedCorrectWords, correctWord => correctWord === word)
}
