import type { Context, SeverityLevel } from '@sentry/types'
import * as Sentry from '@sentry/nextjs'
import { isEmpty } from 'lodash-es'
import { isProd } from '../env'
import { isWuAppWebview } from '../device'
import { WuToastProps, WuToastTypes, toast as wuToast } from '../../components/toast'

export const setUser = (userInfo: { id: string | undefined; name?: string; email: string }) => {
  if (isEmpty(userInfo)) return

  const { id, name, email } = userInfo
  const userId = id ? id.toString() : undefined

  return Sentry.setUser({ id: userId, username: name, email })
}

export const unsetUser = () => {
  Sentry.setUser(null)
}

export const isNotifiableError = (error: any) => {
  if (!error) return false

  return (
    error instanceof Error ||
    (typeof error?.errorClass === 'string' && typeof error?.errorMessage === 'string') ||
    (typeof error?.name === 'string' && typeof error?.message === 'string') ||
    typeof error === 'string'
  )
}

export const notify = ({
  err,
  context = {},
  level = 'error',
  toast,
}: {
  err: unknown
  context: Context
  level?: SeverityLevel
  toast?: {
    type: (typeof WuToastTypes)[number]
    props: WuToastProps
  }
}) => {
  if (isProd && isNotifiableError(err)) {
    Sentry.withScope(scope => {
      level && scope.setLevel(level)
      scope.setExtra('additional', { ...context, isApp: isWuAppWebview() })
      Sentry.captureException(err)
    })
  }

  if (toast) {
    wuToast[toast.type](toast.props)
  }
}

export default { setUser, unsetUser, notify, isNotifiableError }
