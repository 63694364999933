const replacements = [
  { from: /[´`ʹ’‘]/g, to: "'" },
  { from: /[“”ˮ‟„]/g, to: '"' },
  { from: /[…⋯]/g, to: '...' },
  { from: /[‚]/g, to: ',' },
]

export const sanitizedWords = (string: string | undefined | null): string => {
  if (typeof string !== 'string') {
    return ''
  }

  return replacements.reduce((acc, { from, to }) => acc.replace(from, to), string)
}
