import type { MouseEvent } from 'react'
import themeVar from '../../theme/themeVar'
import './wu-font.css'
import { twMerge } from 'tailwind-merge'

const iconMap = {
  checked: '&#xe90d;',
  auto_fix_high: '&#xe907;',
  achievement: '&#xe940;',
  audio_book: '&#xe941;',
  mock_test: '&#xe945;',
  flash_cards: '&#xe943;',
  lecture: '&#xe944;',
  multimedia_content: '&#xe946;',
  play_outlined: '&#xe954;',
  play: '&#xe953;',
  outcome_assignment: '&#xe940;',
  checklist_group: '&#xe942;',
  timed_article: '&#xe941;',
  playlist: '&#xe947;',
  full_test: '&#xe945;',
  question_group: '&#xe948',
  deck: '&#xe943;',
  video: '&#xe949;',
  multimedia_content_group: '&#xe946;',
  article: '&#xe944;',
  swap_horiz: '&#xe970;',
  inventory_2: '&#xe931;',
  delete: '&#xe918;',
  edit: '&#xe91e;',
  info: '&#xe92e;',
  close: '&#xe90e;',
  timer: '&#xe973;',
  share: '&#xe962;',
  arrow_back: '&#xe902;',
  arrow_forward: '&#xe903;',
  move_to_inbox: '&#xe94c;',
  closed_caption: '&#xe90f;',
  contact_page: '&#xe913;',
  storefront: '&#xe96c;',
  group: '&#xe928;',
  school: '&#xe95d;',
  home: '&#xe92d;',
  book: '&#xe909;',
  person: '&#xe952;',
  spoke: '&#xe967;',
  insert_chart: '&#xe92f;',
  comment: '&#xe912;',
  receipt_long: '&#xe958;',
  credit_card: '&#xe915;',
  shopping_cart: '&#xe964;',
  supervisor_account: '&#xe96f;',
  edit_document: '&#xe91d;',
  tag: '&#xe972;',
  shopping_bag: '&#xe963;',
  emergency_home: '&#xe91f;',
  check: '&#xe90d;',
  lock: '&#xe93d;',
  collections_bookmark: '&#xe910;',
  toc: '&#xe974;',
  comic_bubble: '&#xe911;',
  savings: '&#xe95c;',
  help: '&#xe92c;',
  touch_app: '&#xe975;',
  quiz: '&#xe948;',
  menu_book: '&#xe94a;',
  cancel_filled: '&#xe90b;',
  warning: '&#xe97d;',
  rhombus: '&#xe95b;',
  star_filled: '&#xe968;',
  star: '&#xe96a;',
  star_half: '&#xe969;',
  directions_run: '&#xe91b;',
  local_library: '&#xe93c;',
  search: '&#xe95e;',
  filter_alt: '&#xe924;',
  keyboard_arrow_right: '&#xe934;',
  keyboard_arrow_left: '&#xe933;',
  local_fire_department: '&#xe93b;',
  view_list: '&#xe978;',
  question_mark: '&#xe955;',
  folder_open: '&#xe925;',
  link: '&#xe938;',
  facebook: '&#xe923;',
  instagram: '&#xe930;',
  youtube: '&#xe97f;',
  line: '&#xe937;',
  auto_stories: '&#xe908;',
  content_copy: '&#xe914;',
  bullet: '&#xe90a;',
  check_circle: '&#xe90c;',
  military_tech: '&#xe94b;',
  sell: '&#xe95f;',
  add: '&#xe900;',
  auto_awesome: '&#xe906;',
  keyboard_arrow_down: '&#xe932;',
  keyboard_arrow_up: '&#xe935;',
  credit_score: '&#xe916;',
  group_add: '&#xe927;',
  dangerous: '&#xe917;',
  sync_alt: '&#xe971;',
  sentiment_sad: '&#xe961;',
  notification_add: '&#xe94e;',
  notifications_off: '&#xe94f;',
  manage_accounts: '&#xe93f;',
  reviews: '&#xe95a;',
  moving: '&#xe94d;',
  devices: '&#xe91a;',
  volunteer_activism: '&#xe97c;',
  emoji_flags_filled: '&#xe920;',
  emoji_flags: '&#xe921;',
  error: '&#xe922;',
  refresh: '&#xe959;',
  list_alt: '&#xe93a;',
  list_alt_add: '&#xe939;',
  layers: '&#xe936;',
  smart_display: '&#xe965;',
  design_services: '&#xe919;',
  all_inclusive: '&#xe901;',
  send_to_mobile: '&#xe960;',
  handshake: '&#xe929;',
  attach_money: '&#xe905;',
  mail: '&#xe93e;',
  social_leaderboard: '&#xe966;',
  stars_filled: '&#xe96b;',
  widgets: '&#xe97e;',
  stylus_note: '&#xe96e;',
  style: '&#xe96d;',
  volume_up: '&#xe97b;',
  play_circle: '&#xe954;',
  quick_reference_all: '&#xe956;',
  headset_mic: '&#xe92a;',
  hearing: '&#xe92b;',
  draw: '&#xe91c;',
  overview: '&#xe951;',
  grid_view: '&#xe926;',
  visibility: '&#xe97a;',
  visibility_off: '&#xe979;',
  notifications: '&#xe950;',
  vertical_align_top: '&#xe977;',
  vertical_align_bottom: '&#xe976;',
  text_snippet: '&#xe981;',
  confirmation_number: '&#xe983;',
  menu: '&#xe985;',
  remove: '&#xe993;',
  alarm: '&#xe982;',
  send: '&#xe994;',
  emoji_people: '&#xe984;',
  more_horiz: '&#xe986;',
  monitoring: '&#xe980;',
  more_vert: '&#xe987;',
  open_in_full: '&#xe988;',
  open_in_new: '&#xe989;',
  output: '&#xe98a;',
  package: '&#xe98b;',
  palette: '&#xe98c;',
  person_add: '&#xe98d;',
  photo_camera: '&#xe98e;',
  photo_library: '&#xe98f;',
  photo: '&#xe990;',
  picture_as_pdf: '&#xe991;',
  push_pin: '&#xe992;',
  text_format: '&#xe995;',
  translate: '&#xe996;',
  zoom_out: '&#xe997;',
  zoom_in: '&#xe998;',
  footprint: '&#xe99a;',
  data_check: '&#xe999;',
  rss_feed: '&#xe99b;',
  featured_seasonal_and_gifts: '&#xe99d;',
  sentiment_excited: '&#xe99f;',
  charger: '&#xe99e;',
  apartment: '&#xe9a0;',
  repeat: '&#xe9a2;',
  rocket_launch: '&#xe9a1;',
  encrypted: '&#xe99c;',
  upload: '&#xe9a3;',
  check_indeterminate_small: '&#xe9a5;',
  radar: '&#xe9a4;',
  attachment: '&#xe9a7;',
  download: '&#xe9a6;',
  bookmark_star: '&#xe9a8;',
  auto_fix: '&#xe907;',
  local_atm: '&#xe9ae;',
  airplane_ticket: '&#xe9ad;',
  festival: '&#xe9ac;',
  explore: '&#xe9ab;',
  thumb_up: '&#xe9aa;',
  thumb_down: '&#xe9a9;',
  imagesmode: '&#xe990;',
  playlist_remove: '&#xe9af;',
  arrow_drop_down: '&#xe9b3;',
  arrow_drop_up: '&#xe9b4;',
  text_fields: '&#xe9b2;',
  video_library: '&#xe9b1;',
  width: '&#xe9b0;',
  chevron_left: '&#xe933;',
  setting: '&#xe9b6;',
  undo: '&#xe9b5;',
  logout: '&#xe9b9;',
  phone_iphone: '&#xe9b8;',
  desktop_mac: '&#xe9b7;',
  edit_note: '&#xe9bc;',
  bookmark_remove: '&#xe9ba;',
  bookmark_add: '&#xe9bb;',
  language: '&#xe9bd;',
  campaign: '&#xe9be;',
  pan_zoom: '&#xe9c2;',
  task_alt: '&#xe9bf;',
  headphones: '&#xe9c0;',
  sentiment_satisfied: '&#xe9c1;',
  fact_check: '&#xe9c4;',
  ios_share: '&#xe9c5;',
  sentiment_content: '&#xe9c6;',
}

export type IconMapType = keyof typeof iconMap

export type TextColorTypes = keyof typeof themeVar.color.palette

export type IconTypes = {
  type: IconMapType
  onClick?: (e: MouseEvent<HTMLElement>) => void
  style?: React.CSSProperties
  size?: number
  className?: string
  color?: TextColorTypes
}

export const Icon = ({ type, onClick, style, size = 20, className = '', color }: IconTypes) => {
  const icon = iconMap[type]

  if (!icon) {
    return null
  }

  const inlineStyles: React.CSSProperties = {
    color: color ? themeVar.color.palette[color] : 'inherit',
    ...style,
    width: `${size}px`,
    height: `${size}px`,
    fontSize: `${size}px`,
    fontStyle: 'normal',
  }

  return (
    <i
      className={twMerge('wu-icon', onClick && 'cursor-pointer hover:opacity-60', className)}
      style={inlineStyles}
      dangerouslySetInnerHTML={{ __html: icon }}
      onClick={onClick}
    />
  )
}
