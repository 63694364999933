import PackageInfo from 'package.json'
import { i18n } from 'next-i18next'
import {
  createApiCaller,
  type ApiCallerConfigType,
  type CreateAxiosInstanceParams,
} from '@wordup/apis'
import { toast } from '@libs-components/components/toast'
import Swal from '@libs-components/components/sweetalert'
import { isWuAppWebview, getWuAppPlatformInfo } from '@libs-components/utils/device'
import { notify } from '@libs-components/utils/error-notifier'
import { credentialManager } from '../../utils'
import { isExpectedError } from './is-expected-error'

const GENERIC_API_ERR_MESSAGE =
  '非常抱歉，內部系統發生問題，我們會儘快處理。也請您可以用聊天視窗告知我們異常狀況，謝謝。'

const onResponseSuccess: CreateAxiosInstanceParams['onResponseSuccess'] = response => {
  credentialManager.update(response)
}

const onResponseError: CreateAxiosInstanceParams['onResponseError'] = error => {
  const errorContext = error?.config?.errorContext
  const isValidErrorContext =
    Boolean(errorContext) && typeof errorContext === 'object' && !Array.isArray(errorContext)
  const errorToastMessage = error?.config?.errorToastMessage

  if (error.response?.status === 500) {
    Swal.fire({
      icon: 'warning',
      text: GENERIC_API_ERR_MESSAGE,
      confirmButtonText: '好',
    })
  }

  if (isExpectedError(error)) {
    errorToastMessage && toast.error({ message: errorToastMessage })
  } else {
    notify({
      err: error,
      context: { key: 'response error', ...(isValidErrorContext ? errorContext : {}) },
      toast: errorToastMessage
        ? { type: 'error', props: { message: errorToastMessage } }
        : undefined,
    })
  }
}

export const CLIENT_INFO = `web3/${PackageInfo.version}`

const _apiCaller = createApiCaller({
  initialConfig: {
    baseURL: process.env.NEXT_PUBLIC_BACKEND_API,
    headers: { 'Client-Info': CLIENT_INFO, 'Client-Lang': 'zh-TW' },
  },
  getAuthCredentials: credentialManager.get,
  onResponseError,
  onResponseSuccess,
})

const urlVersionPrefixRegex = /^\/?v\d+\//i
export const apiCaller = <T>(config: ApiCallerConfigType<T>) => {
  config.headers ||= {}

  if (i18n && i18n.resolvedLanguage) {
    config.headers['Client-Lang'] = i18n.resolvedLanguage
  }

  if (isWuAppWebview()) {
    const { platform, version } = getWuAppPlatformInfo()
    config.headers['End-User-Client-Info'] = `${platform}/${version}`
  }

  // Remove version prefix from the URL if present
  if (config.url && urlVersionPrefixRegex.test(config.url)) {
    config.url = config.url.replace(urlVersionPrefixRegex, '')
  }

  return _apiCaller(config)
}
