import { CreatePagePayloadTypes, CreateManagePageComponentsTypes } from './types'
import snakecaseKeys from 'snakecase-keys'

export const getAllPages = () => ({ method: 'get', url: '/manage/pages' })

export const getManagePageById = (pageId: string, orgId: string) => ({
  method: 'get',
  url: `/manage/pages/${pageId}`,
  params: {
    org_id: orgId,
  },
})

export const createMangePage = ({ orgId, ...restProps }: CreatePagePayloadTypes) => ({
  method: 'post',
  url: '/manage/pages',
  data: {
    data: { ...restProps },
    org_id: orgId,
  },
})

export const updateManagePage = ({
  orgId,
  pageId,
  key,
  description,
  ogImage,
  visible,
  pageComponents,
}: {
  orgId: string
  pageId: string
  key?: string
  description?: string
  ogImage?: string
  visible?: boolean
  pageComponents?: CreateManagePageComponentsTypes[]
}) => ({
  method: 'patch',
  url: `/manage/pages/${pageId}`,
  data: {
    data: {
      key,
      description,
      og_image: ogImage,
      visible,
      page_components: pageComponents ? snakecaseKeys(pageComponents, { deep: true }) : undefined,
    },
    org_id: orgId,
  },
})

export const findPageIdByOrg = (orgId: string) => ({
  method: 'get',
  url: '/manage/pages/find_by_org',
  params: {
    org_id: orgId,
  },
})
