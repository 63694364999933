export const getFilename = (fileName: string) =>
  fileName.replace(/\s/gm, '_').split('.').slice(0, -1).join('.')

export const getFileExtension = (fileName: string) => {
  const fileExtensionMatch = fileName?.match(/\.([0-9a-z]+)$/i)

  const fileExtension = fileExtensionMatch ? fileExtensionMatch[1] : ''

  return fileExtension
}

export const sanitizeFileName = (fileName: string) => fileName.replace(/[\s]/gm, '_')

export const getUrlFilename = (url: string) => {
  if (!url) return ''
  return url.substring(url.lastIndexOf('/') + 1).split('?')[0]
}

export const removeFileExtension = (fileName: string) => {
  const lastDotIndex = fileName.lastIndexOf('.')
  if (lastDotIndex === -1) {
    return fileName
  }
  return fileName.substring(0, lastDotIndex)
}
