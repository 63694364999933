import type { ApiCallerConfigType, CreateAxiosInstanceParams } from './types'
import axios, {
  type AxiosInstance,
  type AxiosResponse,
  type InternalAxiosRequestConfig,
} from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { isProd } from '@wordup/utils'

const parseInitalConfig = (config: CreateAxiosInstanceParams['initialConfig']) => {
  if (!config.headers) config.headers = {}
  Reflect.set(config.headers, 'Content-Type', 'application/json')
  return config
}

export const createApiCaller = ({
  initialConfig,
  getAuthCredentials,
  onResponseSuccess,
  onResponseError,
}: CreateAxiosInstanceParams) => {
  const axiosInstance: AxiosInstance = axios.create(parseInitalConfig(initialConfig))

  axiosInstance.interceptors.request.use(
    config => {
      let authCredentials: ReturnType<NonNullable<typeof getAuthCredentials>>
      if (getAuthCredentials && (authCredentials = getAuthCredentials())) {
        config.headers['access-token'] = authCredentials['access-token']
        config.headers['uid'] = authCredentials['uid']
        config.headers['client'] = authCredentials['client']
      }
      return config
    },
    error => {
      return Promise.reject(error)
    },
  )

  axiosInstance.interceptors.response.use(
    response => {
      if (response.data) {
        response.data = camelcaseKeys(response.data, { deep: true })
      }
      if (onResponseSuccess) {
        const promiseOrResponse = onResponseSuccess(response)
        if (promiseOrResponse) return promiseOrResponse
      }
      return response
    },
    async error => {
      const shouldThrowError = !error?.config?.ignoreThrowError || !error?.config?.isIgnoreError
      if (onResponseError) {
        const promiseOrError = onResponseError(error)
        if (shouldThrowError && promiseOrError) return promiseOrError
      }
      return shouldThrowError ? Promise.reject(error) : undefined
    },
  )

  const apiCaller = async <T>(config: ApiCallerConfigType<T>): Promise<AxiosResponse<T>> => {
    if (!isProd) {
      if (config.mockData) {
        console.warn(`
          --------------------------------------
          Please notice this warning. \n
          The mock data found in the staging, the mock data returned.
          Create the mock data will be useful for frontend blocked by backend or testing.
          --------------------------------------
        `)
        const mockResp: AxiosResponse<T> = {
          ...config.mockData,
          status: config.mockData.status || 200,
          headers: { 'Content-Type': 'application/json', ...(config.headers || {}) },
          config: config as InternalAxiosRequestConfig<T>,
          statusText: 'Mock data will return from caller',
        }

        return mockResp.status < 400 ? Promise.resolve(mockResp) : Promise.reject(mockResp)
      }
    }

    return axiosInstance.request(config)
  }

  return apiCaller
}
