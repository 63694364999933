import { BreadcrumbJsonLd, type BreadCrumbJsonLdProps } from 'next-seo'
import { SEO_TITLE, SEO_CANONICAL_TEMPLATE } from '../constants'
import { useCanonical } from '../use-canonical'

type BreadcrumbSeoProps = Omit<BreadCrumbJsonLdProps, 'itemListElements'> &
  Partial<Pick<BreadCrumbJsonLdProps, 'itemListElements'>>

const BreadcrumbSeo = ({ itemListElements = [], ...props }: BreadcrumbSeoProps) => {
  const canonical = useCanonical()
  return (
    <BreadcrumbJsonLd
      {...props}
      itemListElements={[
        {
          position: 1,
          name: SEO_TITLE,
          item: process.env.NEXT_PUBLIC_SELF_DOMAIN,
        },
        ...itemListElements
          // Filter out the shop breadcrumb SEO element if it exists
          .filter(element => {
            if (element.item === process.env.NEXT_PUBLIC_SELF_DOMAIN) return false
            if (element.name === SEO_TITLE) return false
            return true
          })
          // Update the position value based on the array index, starting from 2
          .map((element, index) =>
            element.item === SEO_CANONICAL_TEMPLATE
              ? { ...element, item: canonical, position: index + 2 }
              : { ...element, position: index + 2 },
          ),
      ]}
    />
  )
}

export { BreadcrumbSeo, type BreadcrumbSeoProps }
