import { isProd } from '../env'

const wrapWarnedOnce = <Args extends any[], R>(func: (...args: Args) => R, messages: string[]) => {
  let warnedOnce = false
  return (...args: Args): R => {
    if (!isProd && !warnedOnce) {
      console.warn(messages.concat(['\n']).join('\n'))
      warnedOnce = true
    }
    return func(...args)
  }
}

export { wrapWarnedOnce }
