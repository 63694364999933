export const postFeedbackForFeatures = ({
  rating,
  content,
  kind,
  device,
  willingToInterview,
}: {
  rating: number
  content: string
  kind: 'ai_sentence' // backend only supports 'ai_sentence' for now
  device: string // ios, android, web?
  willingToInterview: boolean
}) => ({
  method: 'POST',
  url: '/v1/user_feedback_for_features',
  data: {
    rating,
    content,
    kind,
    device,
    willing_to_interview: willingToInterview,
  },
})

export const getFeedbackForFeatures = ({
  kind,
}: {
  kind: 'ai_sentence' // backend only supports 'ai_sentence' for now
}) => ({
  method: 'GET',
  url: '/v1/user_feedback_for_features',
  params: {
    kind,
  },
})
