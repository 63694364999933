export const vimeoRegex = () =>
  // eslint-disable-next-line no-useless-escape
  /(?:http|https)?:?\/?\/?(?:www\.)?(?:player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/

export const isYoutubeUrl = (url: string) => {
  // https://stackoverflow.com/questions/28735459/how-to-validate-youtube-url-in-client-side-in-text-box
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/
  const match = url.match(regExp)
  return match && match[2].length === 11
}

export const isVimeoUrl = (url: string) => {
  return vimeoRegex().test(url)
}

export const isValidVideoUrl = (videoUrl: string) => {
  if (!videoUrl) return false
  if (isYoutubeUrl(videoUrl)) return true
  if (isVimeoUrl(videoUrl)) return true
  return Boolean(videoUrl.match(/^https:\/\/.*\.(m3u8|mp4)(\?.*)?$/))
}

export const isValidAudioName = (filename: string) => {
  const result = filename.match(/[^\w-_]/)
  return !(result && result.length > 0)
}

export const webUrlRegex = () => /^(ftp|http|https):\/\/[^ "]+$/

export const isValidAudioUrl = (url: string): boolean => {
  const httpsRegex = /^https:\/\//i // starts with https://
  const endsRegex = /\.(mp3|wav)$/i // ends with .mp3
  return httpsRegex.test(url) && endsRegex.test(url)
}

export const integerRegex = () => {
  return /^[0-9]\d*(\d+)?$/i
}

export const isInteger = (v: string | number) => {
  return integerRegex().test(String(+v))
}

export const phoneNumberRegex = () => /^(09)([0-9]{2})([-]?)([0-9]{6})$/
