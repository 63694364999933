const EXAM_RETAKE_NOT_ALLOWED = 'exam_retake_not_allowed'
const EXAM_NOT_IN_ACTIVE_PERIOD = 'exam_not_in_active_period'
const INVALID_LOGIN_CREDENTIALS = 'invalid_login_credentials'
const TRIAL_QUOTA_EXCEED = 'trial_quota_exceed'
const FEATURE_IN_MAINTENANCE = 'feature_in_maintenance'
const AI_FEATURE_QUOTA_EXCEED = 'ai_feature_quota_exceed'
const COURSE_ALREADY_REDEEMED = 'course_already_redeemed'
const COURSE_ALREADY_TRIALED = 'already_trial'
const INVALID_COUPON_CODE = 'invalid_coupon_code'
const ORDER_NOT_FOUND = 'order_not_found'
const ALREADY_CLAIMED = 'already_claimed'
const GRADE_MISMATCH = 'grade_mismatch'
const PAYMENT_GATEWAY_FAILED = 'payment_gateway_failed'
const SHOP_LEGACY_FORMAT = 'shop_legacy_format'
const SHOP_ITEM_NOT_AVAILABLE = 'shop_item_not_available'
const BUYING_GROUP_CLOSED = 'buying_group_closed'
const BUYING_GROUP_FAILED = 'payment_gateway_failed'
const SHOP_ITEM_PENDING_RELEASE = 'shop_item_pending_release'
const COUPON_NO_LEFT = 'coupon_no_left'
const USER_NOT_LOGGED_IN = 'user_not_logged_in'

export const ErrorCodes = {
  EXAM_RETAKE_NOT_ALLOWED,
  EXAM_NOT_IN_ACTIVE_PERIOD,
  INVALID_LOGIN_CREDENTIALS,
  TRIAL_QUOTA_EXCEED,
  FEATURE_IN_MAINTENANCE,
  AI_FEATURE_QUOTA_EXCEED,
  COURSE_ALREADY_REDEEMED,
  COURSE_ALREADY_TRIALED,
  INVALID_COUPON_CODE,
  ORDER_NOT_FOUND,
  ALREADY_CLAIMED,
  GRADE_MISMATCH,
  PAYMENT_GATEWAY_FAILED,
  SHOP_LEGACY_FORMAT,
  SHOP_ITEM_NOT_AVAILABLE,
  BUYING_GROUP_CLOSED,
  BUYING_GROUP_FAILED,
  SHOP_ITEM_PENDING_RELEASE,
  COUPON_NO_LEFT,
  USER_NOT_LOGGED_IN,
}
