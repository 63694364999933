export { checkIsSpellingCorrect } from './check-is-spelling-correct'
export { default as cookieStorage } from './cookie-storage'
export * from './uploaded-video'
export * from './error-notifier'
export * from './filename-helper'
export * from './file-size-helper'
export { default as Swal, type CustomizedReactSweetAlertOptions } from './sweetalert'
export { credentialManager } from './credential'
export * from './is-valid-time-stamp'
export * from './regex'
export * from './urls'
export * from './sanitized-words'
export * from './storage'
