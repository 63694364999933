import type { GetMaterialsEligibilitiesParamsType, OutcomePrivacyType } from './types.d'
import { objectRemoveEmptyKeys } from '@wordup/utils'

export const getMaterial = ({ materialId }: { materialId: string }) => ({
  url: `/v1/materials/${materialId}`,
  method: 'GET',
})

export const getMaterials = () => ({
  url: '/v1/materials',
  method: 'GET',
})

export const updateMaterial = ({
  materialId,
  params,
}: {
  materialId: string
  params: any
}): { url: string; method: string; data: any } => ({
  url: `/v1/materials/${materialId}`,
  method: 'PATCH',
  data: params,
})

export const delMaterial = ({
  materialId,
}: {
  materialId: string
}): { url: string; method: string } => ({
  url: `/v1/materials/${materialId}`,
  method: 'DELETE',
})

export const getStudyPlanStatus = ({
  materialId,
}: {
  materialId: string
}): { url: string; method: string; params: { material_id: string } } => ({
  url: '/v1/study_plan',
  method: 'GET',
  params: { material_id: materialId },
})

export const getMaterialToc = ({
  materialId,
}: {
  materialId: string
}): { url: string; method: string } => ({
  url: `/v1/materials/${materialId}/toc`,
  method: 'GET',
})

export const setStudyPlan = ({
  materialId,
  componentIds,
  studyEndsOn,
  daysOfWeek,
}: {
  materialId: string
  componentIds: string[]
  studyEndsOn: string
  daysOfWeek: string[]
}): { url: string; method: string; data: any } => ({
  url: '/v1/study_plan',
  method: 'POST',
  data: {
    material_id: materialId,
    component_ids: componentIds,
    ends_on: studyEndsOn,
    days_of_week: daysOfWeek,
  },
})

export const resetStudyPlan = ({
  materialId,
}: {
  materialId: string
}): { url: string; method: string; params: { material_id: string } } => ({
  url: '/v1/study_plan',
  method: 'DELETE',
  params: { material_id: materialId },
})

export const redeemMaterial = ({
  code,
  schoolId = null,
}: {
  code: string
  schoolId?: string | null
}): { url: string; method: string; data: { school_id: string | null } } => ({
  url: `/v1/redeem_codes/${code}/redemption`,
  method: 'POST',
  data: { school_id: schoolId },
})

export const getChapters = ({
  materialId,
}: {
  materialId: string
}): { url: string; method: string } => ({
  url: `/v1/materials/${materialId}/chapters`,
  method: 'GET',
})

export const getDefaultChapter = ({ materialId }: { materialId: string }) => ({
  url: `/v1/materials/${materialId}/chapters/default`,
  method: 'GET',
})

export const getComponents = ({
  materialId,
  chapterId,
}: {
  materialId: string
  chapterId: string
}): { url: string; method: string } => ({
  url: `/v1/materials/${materialId}/chapters/${chapterId}`,
  method: 'GET',
})

export const getSameTypeComponents = ({
  materialId,
  mode,
}: {
  materialId: string
  mode: string
}) => ({
  url: `/v1/materials/${materialId}/components`,
  method: 'GET',
  params: { mode },
})

export const getUserStudyData = ({
  materialId,
  chapterId,
}: {
  materialId: string
  chapterId: string
}): { url: string; method: string } => ({
  url: `/v1/materials/${materialId}/chapters/${chapterId}/user_data`,
  method: 'GET',
})

export const postComponentCompleted = ({
  materialId,
  componentId,
}: {
  materialId: string
  componentId: string
}): { url: string; method: string } => ({
  url: `/v1/materials/${materialId}/components/${componentId}/complete`,
  method: 'POST',
})

export const resetComponentCompleted = ({
  materialId,
  componentId,
}: {
  materialId: string
  componentId: string
}): { url: string; method: string } => ({
  url: `/v1/materials/${materialId}/components/${componentId}/complete`,
  method: 'DELETE',
})

export const getComponent = ({
  materialId,
  componentId,
  fullTestId,
}: {
  materialId: string
  componentId: string
  fullTestId?: string
}): { url: string; method: string } => {
  let url
  if (fullTestId) {
    url = `/v1/full_tests/${fullTestId}/components/${componentId}`
  } else {
    url = `/v1/materials/${materialId}/components/${componentId}`
  }
  return {
    url,
    method: 'GET',
  }
}

export const pretestStatus = ({
  materialId,
}: {
  materialId: string
}): { url: string; method: string } => ({
  url: `/v1/materials/${materialId}/pretest`,
  method: 'GET',
})

export const publishersOfM = ({
  materialId,
}: {
  materialId: string
}): { url: string; method: string } => ({
  url: `/v1/materials/${materialId}/publishers`,
  method: 'GET',
})

export const updateMcResult = ({
  materialId,
  componentId,
  tAId,
  status,
  userOralAudioUrl,
}: {
  materialId: string
  componentId: string
  tAId: string
  status: boolean
  userOralAudioUrl?: string
}): { url: string; method: string; data: any } => {
  const result = objectRemoveEmptyKeys({
    id: tAId.toString(),
    mark: status ? 'correct' : 'wrong',
    user_audio_url: userOralAudioUrl,
  })

  const params = {
    type: 'timed_paragrapth',
    result: [result],
  }

  return {
    url: `/v1/materials/${materialId}/components/${componentId}/update_result`,
    method: 'PATCH',
    data: params,
  }
}

export const videoStudentNotes = ({
  materialId,
  componentId,
}: {
  materialId: string
  componentId: string
}): { url: string; method: string } => ({
  url: `/v1/materials/${materialId}/components/${componentId}/video_student_notes`,
  method: 'GET',
})

export const createVideoStudentNote = ({
  materialId,
  componentId,
  params,
}: {
  materialId: string
  componentId: string
  params: any
}): { url: string; method: string; data: { video_student_note: any } } => ({
  url: `/v1/materials/${materialId}/components/${componentId}/video_student_notes`,
  method: 'POST',
  data: {
    video_student_note: params,
  },
})

export const updateVideoStudentNote = ({
  materialId,
  componentId,
  noteId,
  params,
}: {
  materialId: string
  componentId: string
  noteId: string
  params: any
}): { url: string; method: string; data: { video_student_note: any } } => ({
  url: `/v1/materials/${materialId}/components/${componentId}/video_student_notes/${noteId}`,
  method: 'PATCH',
  data: {
    video_student_note: params,
  },
})

export const deleteVideoStudentNote = ({
  materialId,
  componentId,
  noteId,
}: {
  materialId: string
  componentId: string
  noteId: string
}): { url: string; method: string } => ({
  url: `/v1/materials/${materialId}/components/${componentId}/video_student_notes/${noteId}`,
  method: 'DELETE',
})

export const reportMaterialIssue = ({
  composableId,
  composableType,
  description,
  kind,
}: {
  composableId: string
  composableType: string
  description: string
  kind: string
}): {
  url: string
  method: string
  data: {
    composable_id: string
    composable_type: string
    description: string
    kind: string
  }
} => ({
  url: '/v1/material_issues',
  method: 'POST',
  data: {
    composable_id: composableId,
    composable_type: composableType,
    description,
    kind,
  },
})

export const multimediaContent = ({
  materialId,
  stepId,
  stepGroupId,
}: {
  materialId: string
  stepId: string
  stepGroupId: string
}): {
  url: string
  method: string
  params: { multimedia_content_group_id: string }
} => ({
  url: `/v1/materials/${materialId}/multimedia_contents/${stepId}`,
  method: 'GET',
  params: { multimedia_content_group_id: stepGroupId },
})

export const getCompNavigation = ({
  materialId,
  componentId,
  composableType,
  unfinished,
}: {
  materialId: string
  componentId: string
  composableType?: string
  unfinished?: boolean
}) => ({
  url: `/v1/materials/${materialId}/components/${componentId}/navigations`,
  method: 'GET',
  params: { composable_type: composableType, unfinished },
})

export const getOutcomeAssignment = ({
  materialId,
  componentId,
}: {
  materialId: string
  componentId: string
}) => ({
  method: 'GET',
  url: `/v1/materials/${materialId}/components/${componentId}/outcomes`,
})

export const createOutcome = ({
  materialId,
  componentId,
  data,
}: {
  materialId: string
  componentId: string
  data: {
    outcomeAssignmentId: any
    content: any
    metrics: any
    imageUrls: any
    privacy: any
  }
}): { url: string; method: string; data: any } => ({
  url: `/v1/materials/${materialId}/components/${componentId}/outcomes`,
  method: 'POST',
  data: {
    data: {
      outcome_assignment_id: data.outcomeAssignmentId,
      content: data.content,
      metrics: data.metrics,
      image_urls: data.imageUrls,
      privacy: data.privacy,
    },
  },
})

export const updateOutcome = ({
  materialId,
  componentId,
  outcomeId,
  data,
}: {
  materialId: string
  componentId: string
  outcomeId: string
  data: {
    content: string
    metrics: { id: string; score: number }[]
    imageUrls: string[]
    privacy: OutcomePrivacyType
  }
}): { url: string; method: string; data: any } => ({
  url: `/v1/materials/${materialId}/components/${componentId}/outcomes/${outcomeId}`,
  method: 'PATCH',
  data: {
    data: {
      content: data.content,
      metrics: data.metrics,
      image_urls: data.imageUrls,
      privacy: data.privacy,
    },
  },
})

export const getFullTests = ({
  materialId,
}: {
  materialId: string
}): { url: string; method: string } => ({
  url: `/v1/materials/${materialId}/full_tests`,
  method: 'GET',
})

export const getFullTestPasses = (materialId: string) => {
  return {
    method: 'GET',
    url: `/v1/materials/${materialId}/passes`,
  }
}

export const unlockFullTest = ({
  materialId,
  fullTestId,
}: {
  materialId: string
  fullTestId: string
}): { url: string; method: string } => ({
  url: `/v1/materials/${materialId}/full_tests/${fullTestId}/unlock`,
  method: 'PATCH',
})

export const getTestPointsConfig = (materialId: string) => ({
  url: `/v1/materials/${materialId}/test_points`,
  method: 'GET',
})

export const getExamSectionsConfig = (materialId: string) => ({
  url: `/v1//materials/${materialId}/exam_sections`,
  method: 'GET',
})

export const getMaterialPublishers = (materialId: string) => ({
  url: `/v1/materials/${materialId}/publishers`,
  method: 'GET',
})

export const getUnfinishedComponents = ({
  materialId,
  mode,
}: {
  materialId: string
  mode: string
}) => ({
  url: `/v1/materials/${materialId}/unfinished_components`,
  method: 'GET',
  params: { mode, material_id: materialId },
})

export const getMetricTemplates = ({
  materialId,
  outcomeAssignmentId,
  componentId,
}: {
  materialId?: string
  outcomeAssignmentId?: string
  componentId?: string
}) => {
  return {
    url: `/v1/outcome_assignments/${outcomeAssignmentId}/metric_templates?material_id=${materialId}&component_id=${componentId}`,
    method: 'GET',
  }
}

export const getPlaylistItem = ({
  materialId,
  playlistId,
  playlistItemId,
}: {
  materialId?: string
  playlistItemId: string
  playlistId: string
}) => {
  return {
    url: `/v1/materials/${materialId}/playlist_items/${playlistItemId}`,
    method: 'GET',
    params: {
      playlist_id: playlistId,
    },
  }
}

export const getMaterialsEligibilities = ({
  materialId,
  keys,
}: GetMaterialsEligibilitiesParamsType) => ({
  url: `/v1/materials/${materialId}/eligibilities`,
  method: 'GET',
  params: {
    keys,
  },
})
