import { useRef, useEffect, useCallback } from 'react'

type TimeEntry = {
  startTime: number
  endTime: number | null
}

type QuestionTimeMap = {
  [questionId: string]: TimeEntry[]
}

export type QuestionDurationsType = {
  [questionId: string]: {
    durationInMs: number
  }
}

export const useQuestionsDuration = (currentQuestionId?: string) => {
  const timeEntriesRef = useRef<QuestionTimeMap>({})

  useEffect(() => {
    if (!currentQuestionId) return

    // Initialize current question's start time
    if (!timeEntriesRef.current[currentQuestionId]) {
      timeEntriesRef.current[currentQuestionId] = []
    }

    timeEntriesRef.current[currentQuestionId].push({
      startTime: new Date().getTime(),
      endTime: null,
    })

    return () => {
      const entries = timeEntriesRef.current[currentQuestionId]
      if (entries && entries.length > 0) {
        const lastEntry = entries[entries.length - 1]
        if (lastEntry.endTime === null) {
          lastEntry.endTime = new Date().getTime()
        }
      }
    }
  }, [currentQuestionId])

  const getAllQuestionsDuration = useCallback((): QuestionDurationsType => {
    const now = new Date().getTime()
    const durations: QuestionDurationsType = {}

    Object.entries(timeEntriesRef.current).forEach(([questionId, entries]) => {
      const totalDuration = entries.reduce((sum, entry) => {
        const endTime = entry.endTime || now
        return sum + (endTime - entry.startTime)
      }, 0)

      durations[questionId] = {
        durationInMs: totalDuration,
      }
    })

    return durations
  }, [])

  return {
    getAllQuestionsDuration,
  }
}
