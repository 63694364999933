import snakecaseKeys from 'snakecase-keys'
import { GetManageMaterialTagsPayloadTypes, GetManageTagRatingsPayloadTypes } from '../types'

export const getManageMaterialTagGroups = ({ materialId }: { materialId: string }) => ({
  url: '/manage/material_tag_groups',
  method: 'GET',
  params: {
    material_id: materialId,
  },
})

export const getManageMaterialTags = (props: GetManageMaterialTagsPayloadTypes) => ({
  url: '/manage/material_tags',
  method: 'GET',
  params: snakecaseKeys(props, { deep: true }),
})

export const getManageTagRatings = (props: GetManageTagRatingsPayloadTypes) => ({
  url: '/manage/tag_ratings',
  method: 'GET',
  params: snakecaseKeys(props, { deep: true }),
})
