import { useTranslation } from 'next-i18next'
import { formatMoney } from '../../utils/formatter'
import safelyParseJson from '../../utils/safely-parse-json'
import { Text, HighlightableText } from '../../components/text'

type RewardRulesProps = Partial<{
  durationDay: number
  reward: number
  showSkeleton: boolean
}>

const RewardRules = ({ durationDay, reward, showSkeleton }: RewardRulesProps) => {
  const { t } = useTranslation()
  const rules = safelyParseJson<string[]>(
    t('crowdin:product-detail-page.tabs.moneyback.reward-rules.rules'),
    [],
  )

  return (
    <div>
      <Text component='h5' variant='heading_m_500' className='mb-3'>
        {t('crowdin:product-detail-page.tabs.moneyback.reward-rules.title')}
      </Text>
      <ul className='m-0 space-y-1 pl-4'>
        {rules.map(rule => (
          <HighlightableText
            key={rule}
            component='li'
            variant='body_s_400'
            className='whitespace-pre-wrap break-words'
            transProps={{
              values: {
                day: Number.isFinite(durationDay) ? durationDay : null,
                price: Number.isFinite(reward) ? formatMoney(reward as number) : null,
              },
              components: ({ day, price }, { Skeleton, HighlightWithUnderline }) => ({
                HighlightDay: day ? (
                  <HighlightWithUnderline />
                ) : (
                  showSkeleton && (
                    <Skeleton className='inline-block h-5 w-10 align-text-bottom text-[0px]' />
                  )
                ),
                HighlightPrice: price ? (
                  <HighlightWithUnderline />
                ) : (
                  showSkeleton && (
                    <Skeleton className='inline-block h-5 w-10 align-text-bottom text-[0px]' />
                  )
                ),
              }),
            }}
          >
            {rule}
          </HighlightableText>
        ))}
      </ul>
    </div>
  )
}

export { RewardRules, type RewardRulesProps }
