import { isInClient, type Stage } from '../../env'

type SessionStorageOptions<Extra = {}> = { isStagedKey?: boolean } & Extra

type SessionStorageInstance = {
  get: <T = string | null>(key: string, options?: SessionStorageOptions<{ isJSON?: boolean }>) => T
  has: (key: string, options?: SessionStorageOptions) => boolean
  set: (key: string, data: string, options?: SessionStorageOptions) => void
  remove: (key: string, options?: SessionStorageOptions) => void
  clearAll: (cb?: any) => void
  length: () => number
}

const createSessionStorageInstance = (stage: Stage): SessionStorageInstance => {
  const getKey = (key: string, options?: SessionStorageOptions) => {
    return options?.isStagedKey ? key : stage === 'production' ? key : `${stage}-${key}`
  }
  const isJSON = (options?: SessionStorageOptions<{ isJSON?: boolean }>) => {
    return !!options?.isJSON
  }

  // Core operations for session storage
  const instance: SessionStorageInstance = Object.freeze({
    get: (key, options) => {
      const value = sessionStorage.getItem(getKey(key, options))
      if (value === null || value === undefined) return null
      return isJSON(options) ? JSON.parse(value) : value
    },
    has: (key, options) => {
      const value = sessionStorage.getItem(getKey(key, options))
      return value !== null && value !== undefined
    },
    set: (key, data, options) => {
      sessionStorage.setItem(getKey(key, options), data)
    },
    remove: (key, options) => {
      sessionStorage.removeItem(getKey(key, options))
    },
    clearAll: cb => {
      sessionStorage.clear()

      if (typeof cb === 'function') {
        const id = setTimeout(() => {
          cb()
          clearTimeout(id)
        }, 500)
      }
    },
    length: () => sessionStorage.length,
  })

  // Return the proxy instance for intercepting the get method to check if it's in client side
  return new Proxy(instance, {
    get: (target, prop, receiver) =>
      isInClient() ? Reflect.get(target, prop, receiver) : undefined,
  })
}

export { createSessionStorageInstance }
