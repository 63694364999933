import { apiCaller } from '@wordup/lib-portal/features/api'
import { wrapDeprecatedConsole } from '@libs-components/utils/console'

/**
 * @deprecated The fire was moved from `@/apis/fire` to `@wordup/lib-portal/features/api`.
 *
 * You should use `import { apiCaller } from '@wordup/lib-portal/features/api'`
 **/
export default wrapDeprecatedConsole(apiCaller, [
  "You should use `import { apiCaller } from '@wordup/lib-portal/features/api'`",
  '',
  "And also, if you're migrating the codebase,",
  '',
  'please update the naming from `fire` to `apiCaller` to ensure consistency, just like in the shop and wordup-app.',
])
