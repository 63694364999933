import snakecaseKeys from 'snakecase-keys'
import { CreatePostsTypes } from './types'

export const createPost = ({ params }: { params: CreatePostsTypes }) => ({
  url: '/v1/posts',
  method: 'POST',
  data: {
    meta: { ...snakecaseKeys(params) },
  },
})

export const deletePost = ({ postId }: { postId: string }) => ({
  url: `/v1/posts/${postId}`,
  method: 'DELETE',
})
