import { CancelToken } from 'axios'
import _isNil from 'lodash/isNil'

export const startExam = ({
  materialId,
  componentId,
  fullTestId,
}: {
  materialId: string
  componentId?: string
  fullTestId?: string
}) => {
  let payload

  if (fullTestId) {
    payload = {
      material_id: materialId,
      full_test_id: fullTestId,
      full_test_component_id: componentId,
    }
  } else {
    payload = {
      material_id: materialId,
      material_component_id: componentId,
    }
  }

  return {
    method: 'POST',
    url: '/v1/exams/start',
    data: payload,
  }
}

export const postAiPractice = ({
  materialId,
  options = {},
  cancelToken,
}: {
  materialId: string
  options: {
    lastWrongOnly?: boolean
    wrongTimes?: number
    doNotMixContext?: boolean
    tags?: string[]
    examSections?: string[]
    publisherSlugs?: string[]
    levelId?: string
  }
  cancelToken?: CancelToken
}) => {
  const parsed: {
    last_wrong_only?: boolean
    wrong_times?: number
    do_not_mix_context?: boolean
    tags?: string[]
    exam_sections?: string[]
    publisher_slugs?: string[]
  } = {}

  if (!_isNil(options.lastWrongOnly)) {
    parsed.last_wrong_only = options.lastWrongOnly
  }
  if (options.wrongTimes) {
    parsed.wrong_times = options.wrongTimes
  }
  if (!_isNil(options.doNotMixContext)) {
    parsed.do_not_mix_context = options.doNotMixContext
  }
  if (options.tags && options.tags.length > 0) {
    parsed.tags = options.tags
  }
  if (options.examSections && options.examSections.length > 0) {
    parsed.exam_sections = options.examSections
  }
  if (options.publisherSlugs && options.publisherSlugs.length > 0) {
    parsed.publisher_slugs = options.publisherSlugs
  }

  return {
    method: 'POST',
    url: '/v1/exams/ai_practice_lite',
    data: {
      material_id: materialId,
      options: parsed,
      level_id: options?.levelId, // currently only used for subscription practice
    },
    showErrToast: false,
    cancelToken: cancelToken,
  }
}

export const submitExam = ({
  examPaperId,
  answerRows,
}: {
  examPaperId: string
  answerRows: any[]
}) => ({
  method: 'POST',
  url: `/v1/exam_papers/${examPaperId}/answer_sheets`,
  data: {
    answer_sheet_rows: answerRows,
  },
})

export const examContent = ({
  examPaperId,
  lv1GroupId,
  lv2GroupId,
  mode,
}: {
  examPaperId: string
  lv1GroupId?: string
  lv2GroupId?: string
  mode?: string
}) => ({
  method: 'GET',
  url: `/v1/exam_papers/${examPaperId}`,
  params: {
    lv1_group_id: lv1GroupId,
    lv2_group_id: lv2GroupId,
    mode,
  },
})

export const examHistory = ({
  materialId,
  options = {},
  page,
  perPage,
}: {
  materialId: string
  options: {
    lastWrongOnly?: boolean
    wrongTimes?: number
    mode?: string
    levelId?: string
    tags?: string[]
    examSections?: string[]
    publisherSlugs?: string[]
  }
  page: number
  perPage: number
}) => {
  return {
    method: 'POST',
    url: '/v1/exam_histories/get_questions',
    data: {
      material_id: materialId,
      options: {
        wrong_times: options?.wrongTimes
          ? typeof options?.wrongTimes === 'string'
            ? parseInt(options?.wrongTimes, 10)
            : options?.wrongTimes
          : undefined,
        last_wrong_only: options.lastWrongOnly,
        mode: options.mode,
        level_id: options.levelId,
        tags: options.tags,
        exam_sections: options.examSections,
        publisher_slugs: options.publisherSlugs,
      },
      page,
      per_page: perPage,
    },
  }
}

export const examPaperDetail = ({
  examPaperId,
  materialId,
}: {
  examPaperId: string
  materialId: string
}) => ({
  method: 'GET',
  url: `/v1/exam_papers/${examPaperId}/marked`,
  params: {
    material_id: materialId,
  },
})

export const getExamPaperDetail = ({ examPaperId }: { examPaperId: string }) => ({
  method: 'GET',
  url: `/v1/exam_papers/${examPaperId}/marked`,
})

export const fullTestLv1Info = ({ fullTestId }: { fullTestId: string }) => ({
  method: 'GET',
  url: `/v1/full_tests/${fullTestId}/lv1_groups`,
})

export const ftLv1Components = ({
  fullTestId,
  lv1GroupId,
}: {
  fullTestId: string
  lv1GroupId: string
}) => ({
  method: 'GET',
  url: `/v1/full_tests/${fullTestId}/lv1_groups/${lv1GroupId}`,
})

export const patchQuestionBookmark = ({
  questionId,
  bookmarked,
}: {
  questionId: string
  bookmarked: boolean
}) => ({
  method: 'PATCH',
  url: `/v1/questions/${questionId}/bookmark`,
  data: { bookmarked },
})

export const getExamPaperQuestionAllAttempts = ({ questionId }: { questionId: string }) => ({
  method: 'GET',
  url: `/v1/questions/${questionId}/attempts`,
})

export const requestAssessment = ({ answerSheetRowId }: { answerSheetRowId: string }) => {
  return {
    method: 'POST',
    url: '/v1/assessment_requests',
    data: { answer_sheet_row_id: answerSheetRowId },
  }
}

export const cancelAssessment = ({ answerSheetRowId }: { answerSheetRowId: string }) => {
  return {
    method: 'PATCH',
    url: `/v1/assessment_requests/${answerSheetRowId}/cancel`,
  }
}
