import snakecaseKeys from 'snakecase-keys'
import { GetManageUserFeedbacksQueryStringTypes } from './types'

export const updateUserFeedback = ({
  userFeedbackId,
  orgId,
  materialId,
  teacherResponse,
}: {
  userFeedbackId: string
  orgId: string
  materialId: string
  teacherResponse: string
}) => ({
  method: 'patch',
  url: `/v1/manage/user_feedbacks/${userFeedbackId}`,
  params: { org_id: orgId, material_id: materialId },
  data: {
    teacher_response: teacherResponse,
  },
})

export const getManageUserFeedbacks = (props: GetManageUserFeedbacksQueryStringTypes) => {
  return {
    method: 'get',
    url: '/v1/manage/user_feedbacks',
    params: snakecaseKeys(props),
  }
}
